<template>
	<div class="container">
		<div class="content">
			提示条款
		</div>
		<div class="content">
			欢迎您阅读《予睿教育服务协议》（“本协议”）！
		</div>
		<div class="content">
			本协议适用于予睿教育提供的所有产品和服务。予睿教育服务提供者（“我们”）提醒您，为维护您的权利，在使用予睿教育各项产品或服务前，请您务必审慎阅读并透彻理解本协议各条款内容。如您对本协议有任何疑问、意见或建议，请通过本协议提供的联系方式及时与我们联系。
		</div>
		<div class="content">
			在确认充分理解并同意后，您可以使用予睿教育的产品或服务。当您开始使用予睿教育的产品或服务时，即表示您已充分理解并同意本协议。在阅读本协议、使用予睿教育的产品或服务的过程中，如果您不同意本协议中的任一条款，您可立即停止使用予睿教育的产品或服务。
		</div>
		<div class="title">
			第一部分 定义
		</div>
		<div class="content">
			予睿教育：指予睿教育向用户提供服务的产品载体（包括并不限于APP/网站/公众号等）。
			予睿教育服务提供者：指予睿教育的网络及软件技术服务提供者杭州予睿教育科技有限公司及关联公司。
		</div>
		<div class="content">
			除另有约定外，本协议所用定义与《予睿教育隐私政策》中的定义具有相同的涵义。
		</div>
		<div class="title">
			第二部分 服务协议
		</div>
		<div class="content">
			本服务协议将包含以下内容：
		</div>
		<div class="content">
			一、注册和使用
		</div>
		<div class="content">
			二、用户信息的提供、保护和保证、授权
		</div>
		<div class="content">
			三、予睿教育服务使用规则
		</div>
		<div class="content">
			四、不可抗力及其他免责事由
		</div>
		<div class="content">
			五、未成年人条款
		</div>
		<div class="content">
			六、网络安全
		</div>
		<div class="content">
			七、适用及变更
		</div>
		<div class="content">
			八、管辖及法律应用
		</div>
		<div class="content">
			九、如何联系我们
		</div>
		<div class="title">
			一、注册和使用
		</div>
		<div class="content">
			（一）用户资格
		</div>
		<div class="content">
			请确认，在您开始注册程序使用予睿教育服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的后果。
		</div>
		<div class="content">
			此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象。
		</div>
		<div class="content">
			（二）账户说明
		</div>
		<div class="content">
			1、账户获得
		</div>
		<div class="content">
			当您阅读并同意本协议，且按照提示完成全部注册程序后，您可获得予睿教育账户。
			如予睿教育系统判断您存在不当注册或不当使用账户的情形，我们可采取封禁账户等措施。
		</div>
		<div class="content">
			2、账户使用
		</div>
		<div class="content">
			您有权使用您设置或确认的手机号码等（“账户名称”）及您设置的密码或短信验证码（账户名称及密码合称“账户”）登录予睿教育。
		</div>
		<div class="content">
			由于您的账户关联您的个人信息，您的账户仅限您本人使用。您享有您账户的使用权。我们享有账户的所有权。在使用中，您不应以任何方式私自转让该使用权，也不应以任何形式盗用他人账户。账户的行为将被视为账户注册用户的行为，用户应当对以其用户账户进行的所有活动和事件负法律责任。在使用中，您不应以任何方式私自转让账号使用权，也不应以任何形式盗用他人账户。
		</div>
		<div class="content">
			如您账户的使用可能危及您的账户安全及/或其他用户信息安全的，我们可采取相应安全措施。
		</div>
		<div class="content">
			3、账户安全
		</div>
		<div class="content">
			您的账户为您自行设置并由您保管，我们在任何时候均不会主动要求您提供您的账户密码。我们强烈建议您务必保管好您的账户。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，您应通过司法、行政等救济途径向侵权行为人追偿。此外，您应对您账户项下的所有行为结果（包括但不限于发布信息、评论）负责。
		</div>
		<div class="content">
			4、日常维护
		</div>
		<div class="content">
			如发现任何未经授权使用您账户登录予睿教育或其他可能导致您账户遭窃、遗失的情况，建议您立即通知我们，并授权我们为保护您的账户信息采取相应措施。请理解，我们对您的任何请求采取行动均需要合理时间，且应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。
		</div>
		<div class="content">
			5、账号注销
		</div>
		<div class="content">
			您需按照予睿教育官方渠道提供的注销流程和操作指引，在相关功能设置页面进行注销操作，我们会在15个工作日内完成您注销申请的核查和处理，具体注销途径参见《予睿教育隐私政策》；请您务必仔细阅读、充分理解协议中以下条款：
		</div>
		<div class="content">
			（1）账号一旦被注销将不可恢复。
		</div>
		<div class="content">
			（2）在账号注销期间，如果您的账号涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，平台有权自行终止此账号的注销操作而无需另行得到您的同意。
		</div>
		<div class="content">
			（3）注销此账号后您将无法再使用此账号，也无法找回您账号中及与此账号中相关的任何内容或信息，包括但不限于：
		</div>
		<div class="content">
			i.您将无法登录、使用此账号。
		</div>
		<div class="content">
			ii.此账号的个人资料和历史信息（包括但不限于用户名、头像、个人主页信息、聊天记录、投递记录、发言记录、购买订单等）都将无法找回。
		</div>
		<div class="content">
			iii.您购买的未过期的增值服务、虚拟礼物等产品视为您自行放弃，将无法继续使用。您理解并同意，注销后，我们无法帮助您重新恢复以上服务。
		</div>
		<div class="content">
			iv.注销账号并不代表该账号注销前的行为和相关责任得到豁免或减轻。
		</div>
		<div class="title">
			二、用户信息的提供、保护和保证、授权
		</div>
		<div class="content">
			（一）用户信息的提供、保护
		</div>
		<div class="content">
			1、信息的真实合法和更新
		</div>
		<div class="content">
			在注册使用予睿教育服务时，需要您按页面的提示准确完整地提供您的信息（包括您的姓名、联系电话等）。您了解并同意，您应提供真实且有效的信息。您须对在予睿教育的注册信息的真实性、合法性、有效性承担全部责任，不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册账户导致其他用户误认；否则予睿教育有权立即停止提供服务，收回其账户并由用户独自承担由此而产生的一切法律责任。
		</div>
		<div class="content">
			为给您提供更好的服务，需要您及时更新您提供的信息。在法律有明确规定要求平台服务提供者必须对用户信息进行核实的情况下，我们将依法进行检查核实，需要您配合提供最新、真实、完整、有效的信息。
		</div>
		<div class="content">
			如您未及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人等造成的损失与后果。我们可能向您发出询问通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部予睿教育服务。请您理解，为提高您通过认证的效率，如您申请认证时填写的“姓名”、“公司”、“职位”与主动提交的“认证资料”中信息不一致，系统将根据您主动提供的认证资料调整，使“姓名”、“公司”、“职位”与“认证资料”中保持一致。
		</div>
		<div class="content">
			2、用户个人信息的保护
		</div>
		<div class="content">
			保护用户信息是我们的一项基本原则。我们将按照本协议及《予睿教育隐私政策》的规定处理您的个人信息。本协议对信息保护相关内容未作明确规定的，均以《予睿教育隐私政策》的内容为准。我们希望通过《予睿教育隐私政策》向您清楚地介绍对用户个人信息的处理方式，因此建议您完整地阅读《予睿教育隐私政策》，以帮助您更好地保护个人信息。
		</div>
		<div class="content">
			（二）用户信息的保证、授权
		</div>
		<div class="content">
			1、用户信息的保证
		</div>
		<div class="content">
			为了维护互联网环境，保护权利人相应的权利，请您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，我们可对您发布的信息依法或依本协议采取删除或屏蔽等措施，以减轻因不当行为而造成的影响。
		</div>
		<div class="content">
			您应当确保您所发布的信息不包含以下内容：
		</div>
		<div class="content">
			（1）违反国家法律法规禁止性规定的；
		</div>
		<div class="content">
			（2）封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
		</div>
		<div class="content">
			（3）欺诈、虚假、不准确或存在误导性的；
		</div>
		<div class="content">
			（4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
		</div>
		<div class="content">
			（5）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
		</div>
		<div class="content">
			（6）存在可能破坏、篡改、删除、影响予睿教育系统正常运行或未经授权秘密获取予睿教育及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
		</div>
		<div class="content">
			（7）其他违背社会公共利益或公共道德不适合在予睿教育上发布的。
		</div>
		<div class="content">
			2、用户信息的授权
		</div>
		<div class="content">
			对于您提供、发布及在使用予睿教育服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予我们获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。请确认，您同意我们存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
		</div>
		<div class="content">
			予睿教育作为公开的行业内容社区平台，您通过予睿教育发表的信息均为公开信息，其他第三方也均可以通过予睿教育获取您公开发表的信息。请您注意并知悉，您对任何在予睿教育上发表的信息即认可该信息为公开的信息，并单独对此行为承担法律责任。任何您不愿被其他第三人获知的信息，我们建议您均不要在予睿教育上进行发表。
		</div>
		<div class="content">
			为方便您使用予睿教育的其他相关服务，基于您的单独同意，我们需要/可能将您在账户注册和使用予睿教育服务过程中提供、形成的信息传递给其他相关服务提供者，或从其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。关于您个人信息处理的规则和规范，均以《予睿教育隐私政策》。
		</div>
		<div class="title">
			三、予睿教育服务使用规则
		</div>
		<div class="content">
			（一）在使用予睿教育的产品或服务过程中，您应当遵守如下规则：
		</div>
		<div class="content">
			1、遵守中国法律、法规、行政规章及规范性文件；
		</div>
		<div class="content">
			2、遵守与予睿教育服务有关的协议、政策等文件；
		</div>
		<div class="content">
			3、不应为任何违法、犯罪目的而使用予睿教育服务；
		</div>
		<div class="content">
			4、不应以任何形式侵犯任何第三方的合法权利；
		</div>
		<div class="content">
			5、不应进行任何可能对互联网正常运转造成不利影响的行为。
		</div>
		<div class="content">
			对于您上传到予睿教育网站或平台的任何文本、图片、图形、音频和/ 或视频等，或您的其他使用行为，我们将依据法律法规的规定，保留对其内容监督的权利。
		</div>
		<div class="content">
			（二）社区交友服务
		</div>
		<div class="content">
			予睿教育为用户提供发布观点、评论、图片、视频、转发链接等服务，予睿教育有权对其提供的服务或产品形态进行升级或其他调整，并将及时更新页面告知用户。用户使用予睿教育服务发布、分享、评论相关信息，进行网络社交活动，应当遵守《予睿教育社区管理规范》的规定。
		</div>
		<div class="content">
			（三）求职招聘服务
		</div>
		<div class="content">
			1、求职用户，应当如实提供本人基本信息以及应聘岗位相关的知识、技能、工作经历、教育经历等情况，保证遵守法律、法规对于服务期、从业限制、保密等方面的规定。如个人资料有任何变动，应当及时、准确更新。如因注册信息不真实或更新不及时或其他行为违反法律法规的，用户自行承担相应的不利后果，包括被招聘方不予录用或录用后解除劳动关系等。因此给招聘者、予睿教育或第三人造成损失的，还应承担相应的赔偿责任。
		</div>
		<div class="content">
			2、招聘用户，应当依据法律法规及予睿教育平台的要求，提供营业执照或有关部门批准设立的文件、人力资源服务许可证、经办人身份证件、用人单位的委托证明等资料，通过予睿教育相关资质认证审查后，方可发布招聘信息、进行招聘活动。为保障招聘用户认证信息的持续真实有效，对已经通过认证的招聘用户，予睿教育将有权通过定期或不定期核查的方式，验证认证信息的有效性。如在核查过程中发现招聘用户提供的信息不符合规定或虚假或无效的，予睿教育将依据具体情况，对相关用户采取包括但不限于封禁账号、下架职位、解除合作等平台管制措施，且对于其已支付的服务费用（如有），予睿教育有权不予退还。
		</div>
		<div class="content">
			3、招聘用户在予睿教育发布招聘信息的行为，包括但不限于职位信息、公司简介(包含公司商标、名称、Logo、文字说明等)、配图等，均为用户自行操作。招聘用户应当确保所发布的招聘信息真实合法有效，且不得包含虚假信息、违法违规内容、违反公序良俗的内容、侵犯第三方合法权益的内容，以及未经予睿教育授权的广告信息、恶意骚扰信息等。因所发布的招聘信息不实或违规给候选人、本网站或第三人造成损失的，用户应承担相应的赔偿责任。涉及违法或构成刑事犯罪的，依据法律法规承担相应的责任。
		</div>
		<div class="content">
			4、招聘用户发布招聘信息或利用本网站提供的沟通工具与求职者进行沟通的，不得涉及以下内容：涉及性别、地域等各类歧视；涉及转账、收取费用；涉及夸张性描述、夸大性承诺或虚假宣传；职位要求与职位描述不一致；侮辱、诽谤、骚扰、诋毁、攻击第三人的；与招聘无关及其他可能违反社会公序良俗或伤害求职者权益的情形。
		</div>
		<div class="content">
			5、招聘用户应当保证其提供资料和发布信息的真实性，如前述资料或信息不实或其行为违反法律法规的，用户自行承担相应的不利后果，因此给求职者、予睿教育或第三人造成损失的，还应承担相应的赔偿责任。
		</div>
		<div class="content">
			（四）知识产权条款
		</div>
		<div class="content">
			1、我们提供的与予睿教育的产品或服务相关的各种类的过去有效的、现行有效的、或即将产生的权利归予睿教育服务提供者所有，包括但不限于专利权、商标权、著作权及其他知识产权以及相关申请的权利。未经予睿教育服务提供者书面许可，任何人不得以任何形式使用或创造相关衍生作品，或用于其他任何商业目的。
		</div>
		<div class="content">
			2、用户使用予睿教育过程中，对自身使用予睿教育服务的知识产权条款行为以及通过予睿教育发布、公开的任何信息享有合法权利，承担相应法律责任。同时，用户同意：
		</div>
		<div class="content">
			（1）予睿教育服务提供者对用户发布、公开的信息享有全球范围内的、免费的、不可撤销的、永久的、非独家的使用权，予睿教育有权为展示、传播及推广前述内容之目的，对前述内容进行复制、修改、出版等，将该内容用于予睿教育各种形态的产品和服务上。
		</div>
		<div class="content">
			（2）因用户上传或发布的信息侵犯他人权利，而导致任何第三方向予睿教育服务提供者提出侵权或索赔要求的，用户应承担全部责任。
		</div>
		<div class="content">
			（3）第三方若出于非商业目的，将用户在予睿教育上发布的信息转载在予睿教育之外的地方，应当在正文显著位置注明原作者姓名(或原作者在予睿教育上使用的账号名称)，并注明“原载于予睿教育”，且不得对相关信息进行修改、演绎；但原作者明确表示禁止转载的除外。若需要对信息进行修改、演绎，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。
		</div>
		<div class="content">
			（4）如果任何第三方侵犯了予睿教育用户相关的权利，用户同意授权予睿教育或其指定的代理人代表予睿教育自身或用户对该第三方采取提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解等必要的维权措施，并且用户同意在予睿教育认为必要的情况下参与共同维权。
		</div>
		<div class="content">
			（5）未经予睿教育服务提供者事先书面许可，用户不得自行或授权、协助任何第三方非法抓取予睿教育的任何信息（包括用户在予睿教育上传和发布的任何信息）。“非法抓取”是指采用程序或者非正常浏览等技术手段获取内容数据的行为，包括但不限于采用“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、
			复制、转存、获得数据和信息内容的行为。
		</div>
		<div class="content">
			（五）用户行为违约及处理
		</div>
		<div class="content">
			1、在使用予睿教育的产品或服务时，如您有如下情形之一的，视为违约：
		</div>
		<div class="content">
			（1）违反法律法规等相关规定的；
		</div>
		<div class="content">
			（2）违反本协议、《予睿教育隐私政策》等约定的；
		</div>
		<div class="content">
			2、请确认，您对予睿教育产品或服务的使用承担独立且完全的法律责任。如您有违约行为，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助。因此造成损害的（包括但不限于直接经济损失、商誉损失及支出的赔偿金、律师费、诉讼费等间接经济损失），您应承担全部责任。
		</div>
		<div class="content">
			此外，我们保留对您的使用等行为进行监督的权利。如您被投诉或被发现有违约行为，为及时解决问题，保护各方合法权利，我们可以独立判断并采取相应措施（包括但不限于通过技术手段删除、屏蔽相关内容或断开链接、暂停或中止向您提供服务等）。
		</div>
		<div class="content">
			（六）服务的变更、中断、终止
		</div>
		<div class="content">
			1、请您理解并同意，我们基于经营策略的调整，可能会对服务内容进行变更，也可能会中断、中止或终止服务。
		</div>
		<div class="content">
			2、如发生下列任何一种情形，我们有权中断或终止向您提供服务：
		</div>
		<div class="content">
			（1）根据法律法规规定，您应提交真实信息，而您提供的信息不真实、或与注册时信息不一致又未能提供合理证明。
		</div>
		<div class="content">
			（2）您违反相关法律法规的规定、违反《予睿教育隐私政策》或违反本协议的约定。
		</div>
		<div class="content">
			（3）按照法律法规规定，司法机关或主管部门的要求。
		</div>
		<div class="content">
			（4）出于安全的原因或其他必要的情形。
		</div>
		<div class="content">
			3、您有责任自行备份存储在使用服务中的数据。如果您的服务被终止，我们有权从服务器上永久地删除您的数据,法律法规另有规定的除外。服务中止或终止后，我们没有义务向您提供或返还数据。
		</div>
		<div class="content">
			（七）我们可能发送的信息
		</div>
		<div class="content">
			1、请确认，我们可能自行或由第三方通过短信、电子邮件或电子信息等多种方式向您发送、展示广告或其他信息（包括商业与非商业信息），广告或其他信息的具体发送及展示形式、频次及内容等以实际提供为准。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。关于拒绝定向推送的方式和途径，均以《予睿教育隐私政策》。
		</div>
		<div class="content">
			2、我们将依照相关法律法规要求开展广告业务。对予睿教育的产品或服务中出现的广告，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对因该广告而实施的行为负责。
		</div>
		<div class="content">
			3、我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
		</div>
		<div class="title">
			四、不可抗力及其他免责事由
		</div>
		<div class="content">
			（一）在使用予睿教育的产品或服务的过程中，可能会遇到不可抗力等风险因素，使产品或服务受到影响。不可抗力是指不能预见、不能克服并不能避免且造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失在法律允许的范围内免责。
		</div>
		<div class="content">
			（二）在法律允许的范围内，我们对以下情形导致的服务中断或受阻、损失等后果不承担责任：
		</div>
		<div class="content">
			1、受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
		</div>
		<div class="content">
			2、您或我们的电脑软件、系统、硬件和通信线路出现故障。
		</div>
		<div class="content">
			3、由您的操作不当或通过违法违规、非授权的方式使用本服务。
		</div>
		<div class="content">
			4、程序版本过时、设备的老化和/或其兼容性问题、系统不稳定问题。
		</div>
		<div class="content">
			5、其他我们无法控制或合理预见的情形。
		</div>
		<div class="content">
			（三）在使用予睿教育服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，可能会遇到其他用户损害合法权利（包括但不仅限于知识产权）的行为，我们不对任何信息的准确性、真实性、适用性、合法性作承诺保证或承担责任，也不对因用户的侵权行为造成的损害负责。这些风险包括但不限于：
		</div>
		<div class="content">
			1、来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
		</div>
		<div class="content">
			2、遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。
		</div>
		<div class="content">
			3、其他因网络信息或用户行为引起的风险。
		</div>
		<div class="content">
			（四）我们对第三方依托予睿教育提供的内容或服务等无法做承诺或保证，对因前述未作承诺或保证的内容或服务等可能或将导致的直接、间接、偶然、特殊及后续损害，在法律允许的范围内免责。
		</div>
		<div class="content">
			（五）我们依据本协议约定获得处理违法违规内容的权利，该权利不构成我们的义务或承诺，请理解，由于用户行为的不可控性，我们不能保证及时发现违法行为或进行相应处理。
		</div>
		<div class="content">
			（六）在任何情况下，我们强烈建议您不轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份。
		</div>
		<div class="title">
			五、未成年人条款
		</div>
		<div class="content">
			予睿教育产品、网站和服务主要面向成人。我们不会在明知的情况下收集未成年人个人信息。如果您发现我们无意收集了未成年人的个人信息，请您立即通知我们，我们会尽快设法删除相关数据。
		</div>
		<div class="title">
			六、网络安全
		</div>
		<div class="content">
			（一）予睿教育的服务器位于中华人民共和国境内
			，予睿教育的管理和运营发生在中华人民共和国北京市。尽管从全世界均可访问予睿教育，但并非所有通过予睿教育或在予睿教育上讨论、提及、供应或提供的功能、产品或服务均可由所有人或在所有地理位置获得。予睿教育主要服务于在中国人民共和国（不包括香港特别行政区、澳门特别行政区和台湾省）境内有住所或者居住的用户。
		</div>
		<div class="content">
			（二）您不可试图通过非法侵入、破解密码或任何其他非法方式，未经授权访问予睿教育的任何内容或使用本予睿教育的任何服务。你不可追踪、反向查找、破解、破译予睿教育的任何用户信息，包括但不限于任何非您所有的予睿教育账号。
		</div>
		<div class="content">
			（三）您不可违反予睿教育或链接至予睿教育的任何网络安全机制或认证措施，不可探测、扫描或者测试予睿教育或连接至予睿教育的任何网络弱点，您不可向予睿教育发起任何形式的网络攻击以及在予睿教育内或通过予睿教育散播病毒、僵尸和木马。
		</div>
		<div class="content">
			（四）您同意不使用任何设备、软件或程序，干扰或试图干扰予睿教育的正常运行或在予睿教育进行的任何交易，或干扰或试图干扰他人使用予睿教育。你不得采取任何行为，在予睿教育基础架构、系统或网络上，或链接至予睿教育的系统或网络上，存储不合理或不成比例的大量数据。
		</div>
		<div class="content">
			（五）予睿教育可能含有访问其他独立第三方予睿教育的链接，该类链接仅为方便予睿教育的访问者而提供。予睿教育不对链接的内容、服务、信息等提供任何明示和默示的保证，此类链接也不视为予睿教育对链接的推荐、认可或授权，您需谨慎判断与此类链接的互动行为。
		</div>
		<div class="content">
			（六）您与予睿教育之间的网络连接、数据传输的安全性和保密性受您和您使用的电信运营商所采用的技术、或予睿教育之外的其他外部因素影响，尽管予睿教育采取了审慎的内部安全管理制度和操作流程及防范计算机病毒和网络攻击、网络侵入等危害网络安全行为的技术措施，您向予睿教育发送的信息、数据仍存在被他人拦截的可能性，予睿教育无法对您与予睿教育之间的网络连接、数据传输的安全性与保密性提供保证。
		</div>
		<div class="title">
			七、适用及变更
		</div>
		<div class="content">
			（一）适用范围
		</div>
		<div class="content">
			1、我们的所有服务均适用本协议。
		</div>
		<div class="content">
			2、某些服务有其特定的规则或协议，该规则或协议会针对相应的服务有更具体的说明，如本协议与特定服务的规则或协议有不一致之处，请以该规则或协议为准。
		</div>
		<div class="content">
			3、请您注意，本协议不适用由其他公司或个人提供的服务。您使用该等第三方服务需受其服务协议（而非本协议）的约束，需要您仔细阅读其协议内容。
		</div>
		<div class="content">
			（二）变更
		</div>
		<div class="content">
			1、未经您明确同意，我们不会限制您按照本协议所应享有的权利。
		</div>
		<div class="content">
			2、对于重大变更（包括但不限于我们的服务模式发生重大变化），我们还会提供更为显著的通知。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的协议约束。若您不接受修改后的最新协议条款，可立即停止使用予睿教育服务，或注销账户。
		</div>
		<div class="title">
			八、管辖及法律适用
		</div>
		<div class="content">
			1、本协议的成立、生效、履行、解释及因本协议产生的任何争议，均适用中华人民共和国法律（不包括港澳台地区法律）。如法律无相关规定的，参照商业惯例及/或行业惯例。
		</div>
		<div class="content">
			2、您因使用予睿教育产品或服务所产生的与予睿教育服务有关的任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交杭州予睿教育科技有限公司所在地有管辖权的人民法院管辖。
		</div>
		<div class="content">
			3、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
		</div>
		<div class="content">
			4、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效。
		</div>
		<div class="title">
			九、如何联系我们
		</div>
		<div class="content">
			本协议的最终解释权及修改权归予睿教育服务提供者所有。
		</div>
		<div class="content">
			1、如对本协议内容有任何疑问、意见或建议；
		</div>
		<div class="content">
			2、如对予睿教育的产品和服务有任何疑问、意见或建议。
		</div>
		<div class="content">
			本声明自更新之日起生效
		</div>
		<div class="content">
			最近的更新日期：2022年3月4日
		</div>
		<div class="content">
			杭州予睿教育科技有限公司
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		mounted() { //实例被挂载后调用
			let that = this;
			let erd = elementResizeDetectorMaker()
			erd.listenTo(document.getElementById("divport"), function(element) {
				//监听div宽度变化
				let width = element.offsetWidth
		 	let height = element.offsetHeight
				console.log(width)
				console.log(height)
				that.$nextTick(function() {
					let fontSize = width / 80 + 'px';
					let html = document.documentElement;
					html.style.fontSize = fontSize;
					console.log('fontSize', html.style.fontSize)
				})
			})
		},
		created() {},
		methods: {

		}
	}
</script>

<style scoped="">
	html {
		/*7.5为设计稿的宽度*/
		font-size: -webkit-calc(100vw/7.5);
		/*此时定义了在750的设计稿下  1rem=100px*/
	}

	body {
		font-size: 0.24rem;
	}

	.container {
		padding: 0 0.3rem;
	}

	.content {
		font-size: 0.6rem;
		line-height: 0.7rem;
		word-break: break-all;
	}

	.title {
		font-size: 0.9rem;
		margin: 0.2rem 0;
		font-weight: 600;
	}

	.table {
		width: 100%;
		margin-top: 0.3rem;
	}

	.table-head {
		width: 100%;
		background: #eee;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 0.6rem;
	}

	.t1 .table-head-td,
	.t1 .table-td {
		width: 25%
	}

	.t2 .table-head-td,
	.t2 .table-td {
		width: 15%
	}

	.table-head-td,
	.table-td {
		font-size: 0.6rem;
		text-align: center;
		word-break: break-all;
	}

	.table-body {
		width: 100%;
	}

	.table-tr {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 0.6rem;
		border: 0.01rem solid #aaa;
	}
</style>
